import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Image from 'next/image'
import { Box, Typography, useMediaQuery } from '@mui/material'
import ArrowLeft from 'src/components/icons/ArrowLeft'
import ArrowRight from 'src/components/icons/ArrowRight'
import theme, { COLOR, SECONDARY_COLOR } from 'src/styles/theme'
import { rem } from 'src/common/utils/css'
import { COLOR_THEME } from 'src/common/constants'
import TimelineCarousel from 'src/components/media-carousel/Timeline'
import FloatingActionButton from 'src/common/components/floating-action-button/FloatingActionButton'

const PAGINATOR_HANDLE = {
  LEFT: 'left',
  RIGHT: 'right',
}

const PAGINATOR_HANDLE_STATES = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
}

const IMAGE_SHAPES = {
  RECTANGLE: 'rectangular',
  SQUARE: 'square',
}

const SLIDE_IMAGES_PER_HANDLE_CLICK = 1 // index logic would need to be enhanced when if this value is changed

const useStyles = makeStyles()(
  (
    _theme,
    { sliderIndex = 0, minWidth, leftMargin, isImageSquare, backgroundColor }
  ) => {
    let bgColor = ''
    switch (backgroundColor) {
      case COLOR_THEME.BACKGROUND:
        bgColor = COLOR.LIGHT_BACKGROUND
        break

      case COLOR_THEME.CRISSY_FIELD:
        // eslint-disable-next-line prefer-destructuring
        bgColor = SECONDARY_COLOR.LIGHT[40]
        break

      case COLOR_THEME.NEAR_WHITE:
        bgColor = COLOR.NEAR_WHITE
        break

      case COLOR_THEME.BAKER_BEACH_WHITE:
        bgColor = COLOR.BAKER_BEACH_WHITE
        break

      default:
        bgColor = COLOR.LIGHT_BACKGROUND
    }

    return {
      carouselWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: `${rem(40)} ${rem(0)} ${rem(40)} ${rem(24)}`,
        gap: rem(32),
        position: 'relative',
        background: bgColor,
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.up('md')]: {
          padding: `${rem(64)} ${rem(0)} ${rem(64)} ${rem(40)}`,
        },
        [theme.breakpoints.up('xl')]: {
          padding: `${rem(64)} ${rem(0)} ${rem(64)} ${rem(156)}`,
        },
      },
      carouselHeader: {
        display: 'flex',
        flexDirection: 'column',
        gap: rem(16),
        marginRight: rem(24),
      },
      headerMain: {
        color: theme.palette.primary.dark,
      },
      headerBody: {
        ...theme.typography.body.default,
        color: theme.palette.presidio.color.DARK_GRAY,
      },
      carouselFrame: {
        width: '100%',
        display: 'flex',
        gap: rem(24),
        // alignSelf: 'stretch', // or use width:100%
        alignItems: 'flex-start',
        transition: 'all 100ms ease-in-out',
        marginLeft: `${leftMargin}px`,
      },
      imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: rem(12),
        minWidth: minWidth,
        transition: 'all 350ms ease-in-out',
        transform: isImageSquare
          ? `translateX(calc(${sliderIndex} * (-100%) + ${
              leftMargin !== 0 && `calc(25% - (${sliderIndex} * ${rem(24)}))`
            }))`
          : `translateX(calc(${sliderIndex} * (-100%) + ${
              leftMargin !== 0 && `calc(12% - (${sliderIndex} * ${rem(24)}))`
            }))`,
        [theme.breakpoints.up('md')]: {
          maxWidth: minWidth,
          minWidth: `calc(${minWidth} - 12px)`,
          transform: `translateX(calc(${sliderIndex} * (-100%) + ${
            leftMargin !== 0 && `calc(25% - (${sliderIndex} * ${rem(24)}))`
          }))`,
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: minWidth,
          minWidth: minWidth,
        },
      },
      image: {
        position: 'relative',
        width: '100%',
        aspectRatio: isImageSquare ? '1' : '4/3',
      },
      imageCaption: {
        ...theme.typography.helperText,
        color: theme.palette.presidio.color.DARK_GRAY,
      },
      carouselPagination: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        padding: `${rem(0)} ${rem(24)} ${rem(0)} ${rem(0)}`,
        gap: '1rem',
        [theme.breakpoints.up('md')]: {
          padding: `${rem(0)} ${rem(40)} ${rem(0)} ${rem(0)}`,
        },
        [theme.breakpoints.up('xl')]: {
          padding: `${rem(0)} ${rem(156)} ${rem(0)} ${rem(0)}`,
        },
      },
    }
  }
)

function MediaCarousel(props) {
  const { data } = props
  let touchstartX
  let touchendX
  let touchstartY
  let touchendY
  // guards
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { media_carousel, section_id } = data

  const isTimelineCarousel = media_carousel?.mode === 'carousel_timeline'

  if (isTimelineCarousel) return <TimelineCarousel data={data} />

  const xsUp = useMediaQuery(() => theme.breakpoints.up('xs'))
  const mdUp = useMediaQuery(() => theme.breakpoints.up('md'))
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const xlUp = useMediaQuery(() => theme.breakpoints.up('xl'))

  // destructuring values from media_carousel
  const isImageSquare = media_carousel?.image_shapes === IMAGE_SHAPES.SQUARE
  const backgroundColor = media_carousel?.background_color
  const carouselImages = media_carousel?.images || []

  // determining whether to show or hide the pagination
  const { length } = carouselImages
  // determining image width as per screen size
  let minWidth = isImageSquare ? '63%' : '84%'
  if (mdUp) {
    minWidth = isImageSquare ? '42%' : '58%'
  }
  if (lgUp) {
    minWidth = isImageSquare ? '29%' : '38.75%'
  }
  if (xlUp) {
    minWidth = isImageSquare ? '26.5%' : '35.30%'
  }

  // determining images per frame as per screen size
  let imagesPerFrame = 1
  if (mdUp) {
    imagesPerFrame = isImageSquare ? 2 : 1
  }
  if (lgUp) {
    imagesPerFrame = isImageSquare ? 3 : 2
  }

  let showPagination = true
  if (xsUp && length < 2) showPagination = false
  if (mdUp && length < 2) showPagination = false
  if (lgUp && length < 3) showPagination = false
  if (xlUp && length < 3) showPagination = false

  const [sliderIndex, setSliderIndex] = useState(0)

  const [leftPaginatorHandle, setLeftPaginatorHandle] = useState(
    PAGINATOR_HANDLE_STATES.DISABLED
  )
  const [rightPaginatorHandle, setRightPaginatorHandle] = useState(
    length > imagesPerFrame
      ? PAGINATOR_HANDLE_STATES.ACTIVE
      : PAGINATOR_HANDLE_STATES.DISABLED
  )

  // left margin changes as user enters into carousel or comes back to the default state (see figma for details)
  const [leftMargin, setLeftMargin] = useState(0)

  const { classes } = useStyles({
    sliderIndex,
    minWidth,
    leftMargin,
    isImageSquare,
    backgroundColor,
  })

  // determine left margins (values change as per user's position on carousel)
  let leftMarginValue = 0
  if (xsUp) leftMarginValue = -24
  if (mdUp) leftMarginValue = -40
  if (xlUp) leftMarginValue = -156

  function carouselPaginatorClickHandler(handle) {
    let newIndex = sliderIndex

    if (handle === PAGINATOR_HANDLE.RIGHT) {
      setLeftMargin(leftMarginValue)

      newIndex = sliderIndex + SLIDE_IMAGES_PER_HANDLE_CLICK

      if (newIndex > length - imagesPerFrame) {
        setRightPaginatorHandle(PAGINATOR_HANDLE_STATES.DISABLED)
      } else {
        setSliderIndex(newIndex)

        // checking if we have reached the end
        if (newIndex === length - imagesPerFrame) {
          setRightPaginatorHandle(PAGINATOR_HANDLE_STATES.DISABLED)
        } else {
          setRightPaginatorHandle(PAGINATOR_HANDLE_STATES.ACTIVE)
        }
        setLeftPaginatorHandle(PAGINATOR_HANDLE_STATES.ACTIVE)
      }
    } else {
      newIndex = sliderIndex - SLIDE_IMAGES_PER_HANDLE_CLICK

      if (newIndex < 0) {
        setLeftPaginatorHandle(PAGINATOR_HANDLE_STATES.DISABLED)
      } else {
        setSliderIndex(newIndex)

        // checking if we have come back to the beginning
        if (newIndex === 0) {
          setLeftPaginatorHandle(PAGINATOR_HANDLE_STATES.DISABLED)
          setLeftMargin(0)
        } else {
          setLeftPaginatorHandle(PAGINATOR_HANDLE_STATES.ACTIVE)
        }
        setRightPaginatorHandle(PAGINATOR_HANDLE_STATES.ACTIVE)
      }
    }
  }

  function handleGesture() {
    if (
      touchendX < touchstartX &&
      touchstartX - touchendX > 80 &&
      Math.abs(touchstartY - touchendY) < 20
    ) {
      carouselPaginatorClickHandler(PAGINATOR_HANDLE.RIGHT)
    }
    if (
      touchendX > touchstartX &&
      touchendX - touchstartX > 80 &&
      Math.abs(touchendY - touchstartY) < 20
    ) {
      carouselPaginatorClickHandler(PAGINATOR_HANDLE.LEFT)
    }
  }

  return (
    <div
      className={`${classes.carouselWrapper} module`}
      id={section_id}
      data-id="section"
    >
      {(media_carousel?.heading || media_carousel?.subheading) && (
        <div className={classes.carouselHeader}>
          <Typography variant="h2" className={classes.headerMain}>
            {media_carousel?.heading}
          </Typography>
          <div className={classes.headerBody}>{media_carousel?.subheading}</div>
        </div>
      )}
      <div className={classes.carouselFrame}>
        {carouselImages.map((carouselImage, index) => (
          <div
            className={classes.imageContainer}
            key={`${carouselImage.url}${index}`}
            onTouchStart={(e) => {
              touchstartX = e.changedTouches[0].screenX
              touchstartY = e.changedTouches[0].screenY
            }}
            onTouchEnd={(e) => {
              touchendX = e.changedTouches[0].screenX
              touchendY = e.changedTouches[0].screenY
              handleGesture()
            }}
            data-ga-location={`carousel_${index + 1}`}
          >
            <div className={classes.image}>
              <Image
                src={carouselImage.url}
                alt={carouselImage.alt || 'Carousel image'}
                title={carouselImage?.title}
                layout="fill"
                objectPosition="center"
                objectFit="cover"
              />
            </div>
            <div className={classes.imageCaption}>{carouselImage.caption}</div>
          </div>
        ))}
      </div>
      {showPagination && (
        <div className={classes.carouselPagination}>
          <Box
            tabIndex={
              leftPaginatorHandle === PAGINATOR_HANDLE_STATES.DISABLED ? -1 : 0
            }
            onClick={() => carouselPaginatorClickHandler(PAGINATOR_HANDLE.LEFT)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === 'Return')
                carouselPaginatorClickHandler(PAGINATOR_HANDLE.LEFT)
            }}
          >
            <FloatingActionButton
              title="left-arrow"
              aria-label="left-arrow"
              aria-labelledby="left-arrow"
              name="left-arrow"
              disabled={
                leftPaginatorHandle === PAGINATOR_HANDLE_STATES.DISABLED
              }
              tabIndex={-1}
              data-ga-location="carousel_left"
            >
              <ArrowLeft gaTag="carousel_left" />
            </FloatingActionButton>
          </Box>
          <Box
            tabIndex={
              rightPaginatorHandle === PAGINATOR_HANDLE_STATES.DISABLED ? -1 : 0
            }
            onClick={() =>
              carouselPaginatorClickHandler(PAGINATOR_HANDLE.RIGHT)
            }
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === 'Return')
                carouselPaginatorClickHandler(PAGINATOR_HANDLE.RIGHT)
            }}
          >
            <FloatingActionButton
              title="right-arrow"
              aria-label="right-arrow"
              aria-labelledby="right-arrow"
              name="right-arrow"
              disabled={
                rightPaginatorHandle === PAGINATOR_HANDLE_STATES.DISABLED
              }
              tabIndex={-1}
              data-ga-location="carousel_right"
            >
              <ArrowRight gaTag="carousel_right" />
            </FloatingActionButton>
          </Box>
        </div>
      )}
    </div>
  )
}

export default MediaCarousel
